export const IMAGE_GCP_URL =
	'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media';

interface IImage {
	fileName?: string;
	url?: string;
	className?: string;
	height?: string;
	width?: string;
	props?: any;
	onLoad?: any;
	onError?: () => void;
	loading?: "lazy" | "eager"
}

export const Image = ({
	fileName,
	url: imageUrl,
	className,
	height,
	width,
	props,
	onLoad,
	onError,
	loading = "eager"
}: IImage) => {
	const url = imageUrl ?? `${IMAGE_GCP_URL}/${fileName}`;
	return (
		<img
			loading={loading}
			src={url}
			alt={fileName}
			className={className ?? ''}
			height={height}
			width={width}
			onLoad={onLoad}
			{...props}
			{...(onError ? { onError } : {})}
		/>
	);
};
