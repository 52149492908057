import { FC, useState } from 'react';

interface IUserImage {
	image: string;
	matchPercentage: string;
}

export const UserImage: FC<IUserImage> = ({ image, matchPercentage }) => {
	const [isError, setIsError] = useState(false);

	return (
		<div className="kyc-user-profile">
			{!image || isError ? (
				<></>
			) : (
				<img
					src={image}
					alt=""
					className="kyc-user-profile__profile-image"
					onError={() => {
						setIsError(true);
					}}
				/>
			)}
			{image && (
				<div className="kyc-user-profile__match-percentage">
					Face match percentage:{' '}
					{matchPercentage ? `${matchPercentage}` : 'N/A'}
				</div>
			)}
		</div>
	);
};
