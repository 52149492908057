export const kycDetailsObj: { [key in any]: string } = {
  idType: "ID Type",
  state: "State",
  country: "Country",
  firstName: "First Name",
  lastName: "Last Name",
  dateOfBirth: "Date Of Birth",
  gender: "Gender",
  address: "Address",
  city: "City",
  zip: "Zip Code",
  phone: "Phone",
  ssn: "SSN",
  Ssn: "SSN",
  document: "KYC Document",
  basicInfo: "KYC Basic Info",
  frontImage: "docImage",
  backImage: "docImage",
  DL: "Drivers License",
  passport: "Passport",
  idNumber: "ID Number",
  conclusion: "Conclusion",
  sex: "sex",
  report: "Rejection reason",
};
