import { KycDetailsWrapper } from "components/kyc-details";
import "./kyc.scss";
import { FC, useCallback } from "react";
import { ISenderKyc } from "./interface";

interface IKYC {
  SenderKyc: ISenderKyc;
  RecipientKyc: ISenderKyc;
  isSender: boolean;
  isRecipient: boolean;
  sessionDetailsInfo: any;
  userDetails: any;
}

export const KYC: FC<IKYC> = ({
  SenderKyc,
  RecipientKyc,
  isSender,
  isRecipient,
  sessionDetailsInfo,
  userDetails,
}) => {
  const kycDetailProps = useCallback((kyc: ISenderKyc) => {
    const { ssn = {}, kyc: kycInfo = {}, status } = kyc ?? {};
    const kycDetails: any = {
      kyc: {
        ...kycInfo,
        ssn: {
          ssn,
        },
      },
      status,
    };

    return {
      kyc: kycDetails,
    };
  }, []);

  console.log({ sessionDetailsInfo, userDetails });
  return (
    <div className="Kyc-wrapper">
      {isSender && (
        <div className="Kyc-wrapper_inner">
          <KycDetailsWrapper
            kycDetail={kycDetailProps(SenderKyc) ?? {}}
            isLoading={false}
            isLoaded={true}
            header="Sender"
            key="sender"
            sessionInfo={sessionDetailsInfo.sender}
            userDetails={userDetails.senderDetails}
          />
        </div>
      )}
      {isRecipient && (
        <div className="Kyc-wrapper_inner">
          <KycDetailsWrapper
            kycDetail={kycDetailProps(RecipientKyc) ?? {}}
            isLoading={false}
            isLoaded={true}
            header="Recipient"
            key="recipient"
            sessionInfo={sessionDetailsInfo.recipient}
            userDetails={userDetails.recipientDetails}
          />
        </div>
      )}
    </div>
  );
};
