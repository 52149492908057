import { Status, Wrapper } from "@googlemaps/react-wrapper";

import "./session.scss";
import { ReactElement, useCallback } from "react";
import { Loader } from "storybook/loader";
import { MapComponent } from "components/maps";

const SessionInfo = ({
  ip,
  device,
  location,
  city,
  country_code,
  region,
}: any) => {
  const mapRender = useCallback((status: Status): ReactElement => {
    if (status === Status.FAILURE) return <></>;
    return <Loader />;
  }, []);

  console.log({ location });

  const { latitude, longitude } = location ?? {};

  const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

  return (
    <div style={{ padding: "0px 16px" }}>
      <div className="session-info__body">
        <div className="session-info__body__details-container">
          <div className="session-info__body__details">
            <div className="session-info__body__icon-container device">
              <i className="ri-smartphone-line" />
            </div>
            <div className="session-info__body__detailed">
              <span className="session-info__body__key">Device OS</span>
              <span className="session-info__body__value">
                {device ?? "NA"}
              </span>
            </div>
          </div>

          <div className="session-info__body__details">
            <div className="session-info__body__icon-container user">
              <i className="ri-user-search-line" />
            </div>
            <div className="session-info__body__detailed">
              <span className="session-info__body__key">User IP</span>
              <span className="session-info__body__value">{ip ?? "NA"}</span>
            </div>
          </div>
          <div className="session-info__body__details">
            <div className="session-info__body__icon-container host">
              <i className="ri-map-pin-2-line" />
            </div>
            <div className="session-info__body__detailed">
              <span className="session-info__body__key">Region</span>
              <span className="session-info__body__value">
                {region ?? "NA".toUpperCase()}
              </span>
            </div>
          </div>
        </div>
        <div className="session-info__body__details-container">
          <div className="session-info__body__details">
            <div className="session-info__body__icon-container browser">
              <i className="ri-global-line" />
            </div>
            <div className="session-info__body__detailed">
              <span className="session-info__body__key">Browser</span>
              <span className="session-info__body__value">{"NA"}</span>
            </div>
          </div>

          <div className="session-info__body__details">
            <div className="session-info__body__icon-container location">
              <i className="ri-user-location-line" />
            </div>
            <div className="session-info__body__detailed">
              <span className="session-info__body__key">City</span>
              <span className="session-info__body__value">{city ?? "NA"}</span>
            </div>
          </div>
          <div className="session-info__body__details">
            <div className="session-info__body__icon-container referrer">
              <i className="ri-flag-line" />
            </div>
            <div className="session-info__body__detailed">
              <span className="session-info__body__key">Country Code</span>
              <span
                className="session-info__body__value"
                style={{ textTransform: "uppercase" }}
              >
                {country_code ?? "NA"}
              </span>
            </div>
          </div>
        </div>
      </div>

      {latitude && longitude && (
        <div className="map_container_body">
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="map_container"
          >
            <Wrapper
              apiKey="AIzaSyCTIQp7ZqdF6ls1ze3r9nwXV4lrVWEoH-8"
              render={mapRender}
            >
              <MapComponent
                center={{
                  lat: latitude,
                  lng: longitude,
                }}
                zoom={11}
              />
            </Wrapper>
          </a>
        </div>
      )}
    </div>
  );
};

export { SessionInfo };
