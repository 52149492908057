import "./face-auth-details.scss";

export const FaceAuthDetails = ({ authBiometric, userName, email }: any) => {
  return (
    <div style={{ padding: 16 }}>
      {/* <div className="face-auth-details__documents">
        <div className="face-auth-details__documents_inner">
          <div className="face-auth-details__documents_inner_title">
            Facial authentication
          </div>
        </div>
        <div className="face-auth-details__lines"></div>
      </div> */}

      <div className="kycDetails-info__documentView">
        <div className="kyc-user-profile">
          <img
            src={authBiometric?.image}
            alt=""
            className="kyc-user-profile__profile-image"
          />
        </div>
        <div className="kycDetail-row">
          <div className="kyc-report-label">
            <div className="kyc-report-label__key"> Name</div>
          </div>
          <div className="kycDetail-row__basicInfoContainer">
            <div>:</div>
            <div className="kyc-report-label-kycValue">{userName ?? "N/A"}</div>
          </div>
        </div>
        {/* <div className="kycDetail-row">
          <div className="kyc-report-label">
            <div className="kyc-report-label__key">Last Name</div>
          </div>
          <div className="kycDetail-row__basicInfoContainer">
            <div>:</div>
            <div className="kyc-report-label-kycValue">TROMBLEY</div>
          </div>
        </div> */}

        <div className="kycDetail-row">
          <div className="kyc-report-label">
            <div className="kyc-report-label__key">Phone/Email</div>
          </div>
          <div className="kycDetail-row__basicInfoContainer">
            <div>:</div>
            <div className="kyc-report-label-kycValue">{email ?? "N/A"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
