import { useCallback, useMemo, useState } from "react";

interface IConfig {
  headers?: {
    Authorization?: string;
    "Content-Type"?: string;
  };
}

const API_HOST = "https://api.stage.simplici.io/v1";

export function getAccessToken() {
  if (/.pp./gi.test(API_HOST)) {
    return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYzNzQ5YzFjZWY5ODI4NWI0NWVmODNiNSIsImNsaWVudElkIjoiNWY4NjM2MWI0ZGJkNDY4MmIzMmY0MjFlM2FjM2ZhNGUiLCJidXNpbmVzcyI6IjYzNmIzYTQ3NDY3ZGE0YmY0ZDY0ZGNjNyIsImlhdCI6MTY2ODU4NjQ4MiwiZXhwIjoxNzAwMTQ0MDgyfQ.5t6Z21BOkS3XckOCOuoupEh9u_BaR-sHcnrncAPwbwc";
  }
  if (/stage/gi.test(API_HOST)) {
    return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NGQzZjczM2U3MDZhOTRmNzc0Yzc5YzIiLCJpYXQiOjE2OTkzMzg4MDZ9.9PZmV88lrkBfXNmMe67vig4QHdnzWht2f12Q3H_pFwk";
  }
  return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYzOThhY2MzNGRjYzE3Y2NhNzRmNjU5OCIsImNsaWVudElkIjoiMjZlZjhlMmJmNTExNGM1NTgwYjJjYjAzN2I5NjEyODEiLCJidXNpbmVzcyI6IjYzMTIwNDdlMTI3OTQ0YWU4ZTE0N2JmYiIsImlhdCI6MTY3MDk1MDE5NywiZXhwIjoxNzAyNDg2MTk3fQ.MLRJJA2iNsOODN5pg9tJzVCbYzmS83r_zLsY3V0iw8M";
  // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYzNmQ2YTRmZWY5ODI4NWI0NTJkNTRkMCIsImNsaWVudElkIjoiNTg5OWE5MzAyNTc3NDE4YzhiMzM0ODBjYzk4NjY3NzQiLCJidXNpbmVzcyI6IjYzMTIwNDdlMTI3OTQ0YWU4ZTE0N2JmYiIsImlhdCI6MTY2ODExNTAyMiwiZXhwIjoxNjcwNzA3MDIyfQ.Jwb_0b5wdXIYrkyYJJfZeGng5F5IoHXLEPqmaoYXASA'
}

export const useNetwork = () => {
  // const { accessToken } = useRecoilValue(loginState);

  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const config: IConfig = useMemo(() => ({}), []);
  const postConfig: IConfig = useMemo(() => ({}), []);

  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  postConfig.headers = {
    "Content-Type": "application/json",
    ...(config.headers ?? {}),
  };

  const get = useCallback(
    async (url: string, customHost?: string): Promise<any> => {
      setLoading(true);

      try {
        const response = await fetch(
          customHost ? customHost + url : API_HOST + url,
          config
        );
        const apiPayload = await response.json();
        setStatus(response?.ok);
        setIsLoaded(true);
        const payload = {
          statusCode: response.status,
          ...apiPayload,
        };
        setData(payload);
        return payload;
      } catch (err: any) {
        setError(err);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [config]
  );

  const post = useCallback(
    async (
      url: string,
      requestJSON: any,
      customHost?: string,
      customConfig?: any
    ) => {
      setLoading(true);
      if (customConfig) {
        postConfig.headers = {
          ...postConfig.headers,
          ...(customConfig?.headers ?? {}),
        };
      }
      try {
        const response = await fetch(
          (customHost ? customHost : API_HOST) + url,
          {
            method: "POST",
            ...postConfig,
            body: JSON.stringify(requestJSON),
          }
        );
        if (response.status === 500) {
          setError(response.type);
          return response.type;
        }
        setStatus(response?.ok);
        const apiData = await response.json();
        if (url.includes("charts?businessId")) {
          setIsLoaded(true);
          setData(apiData);
          return apiData;
        }

        const apiResponse = apiData.data ?? apiData;
        setIsLoaded(true);
        const payload = {
          statusCode: response.status,
          ...apiResponse,
        };
        setData(payload);
        return payload;
      } catch (err: any) {
        setError(err);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [postConfig]
  );

  const formData = useCallback(async (url: string, requestJSON: any) => {
    setLoading(true);
    try {
      const response = await fetch(API_HOST + url, {
        method: "POST",
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        body: requestJSON,
      });
      const apiData = await response.json();
      setStatus(response?.ok);
      setIsLoaded(true);
      setData(apiData);
      setLoading(false);
      return apiData;
    } catch (err) {
      setError(err);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const put = useCallback(
    async (url: string, requestJSON?: any) => {
      try {
        const response = await fetch(API_HOST + url, {
          method: "PUT",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        setStatus(response?.ok);
        const apiData = await response.json();
        setStatus(response.status);
        setIsLoaded(true);
        setData(apiData.data);
        return apiData.data;
      } catch (err: any) {
        setError(err);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [postConfig]
  );

  const remove = useCallback(
    async (url: string, requestJSON?: any) => {
      try {
        const response = await fetch(API_HOST + url, {
          method: "DELETE",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        setStatus(response?.ok);
        const apiData = await response.json();
        setStatus(response.status);
        setIsLoaded(true);
        setData(apiData.data);
        return apiData.data;
      } catch (err: any) {
        setError(err);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [postConfig]
  );

  const patch = useCallback(
    async (url: string, requestJSON?: any) => {
      setLoading(true);
      try {
        const response = await fetch(API_HOST + url, {
          method: "PATCH",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        setStatus(response?.ok);
        const apiData = await response.json();
        setIsLoaded(true);
        const apiResponse = apiData.data ?? apiData;
        const payload = {
          statusCode: response.status,
          ...apiResponse,
        };
        setData(payload);
        const retrunPayload = {
          statusCode: response.status,
          ...apiData.data,
        };
        return retrunPayload;
      } catch (err: any) {
        setError(err);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [postConfig]
  );

  return {
    get,
    post,
    formData,
    put,
    data,
    status,
    error,
    loading,
    setLoading,
    remove,
    patch,
    isLoaded,
    setIsLoaded,
  };
};
