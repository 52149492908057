import React, { useState } from "react";
import { TransactionCard } from "components";
import 'react-toastify/dist/ReactToastify.min.css';
import { Slide, ToastContainer } from 'react-toastify';

import "./App.css";

function App() {
  const [senderRecipientDetails, setSenderRecipientDetails] = useState<any>({
    senderDetails: {
      polygonId: "",
      sessionId: "",
      walletAddress: "",
      balance: "",
      isApiResp: false,
      sessionInfo: {},
    },
    recipientDetails: {
      polygonId: "",
      sessionId: "",
      walletAddress: "",
      balance: "",
      isApiResp: false,
      sessionInfo: {},
    },
    senderAmount: 0,
  });

  const handleOnAddAmount = () => {};

  const props = {
    senderRecipientDetails,
    setSenderRecipientDetails,
    handleOnAddAmount,
  };

  return (
    <div style={{ padding: "24px" }}>
      <TransactionCard {...props} />
      <ToastContainer
					position="top-center"
					autoClose={2000}
					hideProgressBar
					closeOnClick
					pauseOnHover
					transition={Slide}
					theme="dark"
					limit={1}
				/>
    </div>
  );
}

export default App;
