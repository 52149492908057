import { FC, Fragment, useCallback, useMemo } from "react";
import { RejectionDetails } from "./rejection-details";
import { UserImage } from "./user-image";
import { DocumentView } from "./document-view";
import { kycDetailsObj } from "./constant";
import { Image } from "storybook/image";
import { SessionDetailsHeading } from "components/session-details-heading";
import { NoData } from "components/no-data-available";

import "./kyc-details.scss";
import { SessionInfo } from "components/session-info";
import { FaceAuthDetails } from "./face-auth-details";
import { Loader } from "storybook/loader";

interface IKycDetail {
  kyc: {
    kyc: any;
    status: string;
  };
  isTransactionPage?: boolean;
}

interface IKycDetailsWrapper {
  kycDetail: IKycDetail;
  isLoading: boolean;
  isLoaded: boolean;
  header: string;
  sessionInfo?: any;
  userDetails?: any;
  key?: string;
}

const labelObj: any = {
  sender: "Sender",
  recipient: "Recipient",
};

export const KycDetailsWrapper: FC<IKycDetailsWrapper> = ({
  kycDetail,
  isLoading,
  isLoaded,
  header,
  sessionInfo,
  userDetails,
  key = "sender",
}) => {
  const { isTransactionPage = false, kyc: kycWrapper } = kycDetail;

  const { kyc } = kycWrapper ?? {};
  const { ssn, address, ...restKyc } = kyc ?? {};
  const KYCLabelDataKeys = Object?.keys(restKyc ?? {});

  const getVerifiedIcon = useCallback(
    (property: {
      value: string | number | null | undefined;
      verified: boolean | null | undefined;
    }) => {
      const { value, verified } = property ?? {};
      if (!value || verified == null) {
        return <></>;
      } else if (verified) {
        return <Image fileName="Frame_27.svg" />;
      } else if (verified === false) {
        return <Image fileName="Verified_1.svg" />;
      } else {
        return <></>;
      }
    },
    []
  );

  const rowItem = useCallback(
    (key: any, header: any) => {
      if (kycDetailsObj[key]) {
        return (
          <Fragment key={key}>
            <div className="kycDetail-row">
              <div className="kyc-report-label">
                <div className="kyc-report-label__key">
                  {kycDetailsObj[key] ?? key}
                </div>
              </div>
              <div className="kycDetail-row__basicInfoContainer">
                <div>:</div>
                <div className="kyc-report-label-kycValue">
                  {key === "conclusion" &&
                    (kyc?.[header]?.[key] &&
                    (kyc as any)?.[header]?.[key]?.length
                      ? ((kyc as any)?.[header]?.[key] ?? []).map(
                          (conclusion: string, index: number) => (
                            <div
                              className="kyc-report-label-kycValue__conclusion"
                              key={`conclusion_${index.toString()}`}
                            >
                              <i className="ri-circle-fill kyc-report-label-kycValue__conclusion__dot" />
                              {conclusion ?? "NA"}
                            </div>
                          )
                        )
                      : "NA")}
                  {key !== "conclusion" &&
                    key !== "report" &&
                    ((kycDetailsObj[kyc?.[header]?.[key]?.value] ??
                      (typeof kyc?.[header]?.[key]?.value !== "object" &&
                        kyc?.[header]?.[key]?.value)) ||
                      "NA")}
                  {key === "report" && (
                    <RejectionDetails
                      data={kyc?.[header]?.[key]?.reject ?? []}
                    />
                  )}
                </div>
                {getVerifiedIcon(kyc?.[header]?.[key])}
              </div>
            </div>
          </Fragment>
        );
      }
      return <></>;
    },
    [getVerifiedIcon, kyc]
  );

  const renderKycDetails = useCallback(
    (header: any) => {
      const { frontImage, backImage, userPhoto, matchPercentage, ...rest } =
        kyc?.[header] ?? {};
      const details = Object.keys(rest ?? {});
      const elements = [];
      switch (header) {
        case "document": {
          const docImg = (
            <DocumentView
              frontImage={frontImage?.value}
              backImage={backImage?.value}
            />
          );
          elements.push(docImg);
          break;
        }
        case "basicInfo": {
          const docImg = (
            <UserImage
              image={userPhoto?.value}
              matchPercentage={matchPercentage?.value}
            />
          );
          elements.push(docImg);
          break;
        }
        default:
          break;
      }
      elements.push(
        ...details.map((key) => {
          return <Fragment key={key}>{rowItem(key as any, header)}</Fragment>;
        })
      );

      return elements;
    },
    [kyc, rowItem]
  );

  const renderKycHeaders = useMemo(() => {
    const headers = Object.keys(kyc ?? {});
    return (
      <>
        {headers.map((header: any) => {
          return (
            !!kycDetailsObj[header] && (
              <Fragment key={header}>
                <div className="kycDetails-info__documents">
                  <div className="kycDetails-info__documents_inner">
                    <div className="kycDetails-info__documents_inner_title">
                      {kycDetailsObj[header] ?? header}
                    </div>
                  </div>
                  <div className="kycDetails-info__lines" />
                </div>
                <div className="kycDetails-info__documentView">
                  {renderKycDetails(header)}
                </div>
              </Fragment>
            )
          );
        })}
      </>
    );
  }, [kyc, renderKycDetails]);

  const loading = useMemo(
    () => sessionInfo?.status === "pending",
    [sessionInfo]
  );

  const props = {
    ...sessionInfo?.authBiometric,
    ...userDetails,
  };

  const faceProps = {
    authBiometric: sessionInfo?.authBiometric,
    ...userDetails,
  };
  const {approvalHash, txnHash} = sessionInfo ?? {}

  return (
    <div className="kycDetails-contain">
      <SessionDetailsHeading
        label={header}
        icon={
          <i
            className={`ri-${
              header === "Sender" ? "upload" : "download"
            }-2-line form-icon-profile-container__formIcon`}
          />
        }
      ></SessionDetailsHeading>

      {sessionInfo && (
        <Fragment>
          <div style={{ padding: "16px" }}>
            <div className="face-auth-details__documents">
              <div className="face-auth-details__documents_inner">
                <div className="face-auth-details__documents_inner_title">
                  Facial authentication
                </div>
              </div>
              <div className="face-auth-details__lines"></div>
            </div>
            {!loading ? (
              <>
                <div style={{
                  margin: 20
                }}>
                  Approval Hash: <a target="__blank" href={`https://mumbai.polygonscan.com/tx/${approvalHash}`}>{approvalHash}</a>
                <div className="face-auth-details__lines"></div>
                </div>
                <div style={{
                  margin: 20
                }}>
                  Transaction Hash: <a target="__blank" href={`https://mumbai.polygonscan.com/tx/${txnHash}`}>{txnHash}</a>
                <div className="face-auth-details__lines"></div>
                </div>
              <FaceAuthDetails {...faceProps} />
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <Loader type="loader" dimension={30} />
                <div>Waiting for facial authentication</div>
              </div>
            )}
          </div>

          {!loading && (
            <div style={{ padding: "16px" }}>
              <div className="device-details__documents">
                <div className="device-details__documents_inner">
                  <div className="device-details__documents_inner_title">
                    Device Info
                  </div>
                </div>
                <div className="face-auth-details__lines"></div>
              </div>

              <SessionInfo {...props} />
            </div>
          )}
        </Fragment>
      )}

      {!isLoading && isLoaded ? (
        KYCLabelDataKeys?.length > 0 ? (
          <div
            className={`kycDetails-body kycDetails-body${
              isTransactionPage ? "--kyc-transactions-page" : ""
            }`}
          >
            {renderKycHeaders}
          </div>
        ) : (
          <NoData />
        )
      ) : (
        <>
          loader
          {/* <SessionDetailsSkeletonLoader /> */}
        </>
      )}
    </div>
  );
};
