import { useEffect, useRef } from "react";

import "./map.scss";

export const MapComponent = ({
  center,
  zoom,
}: {
  center: any;
  zoom: number;
}) => {
  const mapRef: any = useRef();

  useEffect(() => {
    const map = new (window as any).google.maps.Map(mapRef.current, {
      center,
      zoom,
      zoomControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      keyboardShortcuts: false,
      gestureHandling: "none",
    });
    //@ts-ignore
    new google.maps.Marker({
      position: center,
      map,
    });
    // eslint-disable-next-line
  }, []);

  return <div ref={mapRef} id="map" className="map_container" />;
};
