import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {toast} from 'react-toastify';
import { Input } from "storybook";
import { Button } from "storybook/button";
import { KYC } from "components/kyc/kyc";
import { useNetwork } from "hooks";

import "./transaction-card.scss";
import { Loader } from "storybook/loader";

const waitingStatus = ['completed', "rejected"]

export const TransactionCard = ({
  senderRecipientDetails,
  setSenderRecipientDetails,
}: any) => {
  const { senderDetails, recipientDetails } = senderRecipientDetails;
  const {
    polygonId: senderPoligonId,
    balance: senderBalance,
    walletAddress: senderWalletAddress,
    isApiResp: isSenderApiResp,
  } = senderDetails;

  const [depositLoading, setDepositLoading] = useState(false);
  const [depositLoaded, setDepositLoaded] = useState(false);
  const [depositDetail, setDepositDetail] = useState<{
    status?: "completed" | "rejected" | 'pending';
    _id?: string;
  }>({});

  const {
    polygonId: recipientPoligonId,
    balance: recipientBalance,
    walletAddress: recipientWalletAddress,
    isApiResp: isRecipientApiResp,
  } = recipientDetails;

  const { get: getSessionID } = useNetwork();
  const { get: getWallet } = useNetwork();
  const { post: releaseFund } = useNetwork();
  const notified = useRef({sender: false, recipient: false})
  const [escrowBalance, setEscrowBalance] = useState(null)

  const [senderKyc, setSenderKyc] = useState<any>({});
  const [recipientKyc, setRecipientKyc] = useState<any>({});
  const [senderLoader, setSenderLoader] = useState(false);
  const [recipientLoader, setRecipientLoader] = useState(false);
  const [isReleaseEscrowLoading, setIsReleaseEscrowLoading] = useState(false);
  const [amount, setAmount] = useState(0);

  const { get: getUserDetails, post: postBalance } = useNetwork();
  const { post: deposit, get: getDeposit } = useNetwork();
  const { get: getEscrowBalanceRequest } = useNetwork();

  const getEscrowBalance = () => {
    setInterval(() => {
      getEscrowBalanceRequest(`/escrow-balance`).then((res) => {
        const balance = res?.data?.[0]?.balance;
        setEscrowBalance(balance ?? 0.0);
      });
    }, 2000);
  };

  useEffect(() => {
    getEscrowBalance();
  }, [])

  const getBalance = (key: string, address: string) => {
    const balanceInterval = setInterval(() => {
      postBalance(`/wallet-balance`, {
        address: address,
        assetId: 2,
      }).then((bal) => {
        const balance = Number(bal.balance);

        setSenderRecipientDetails((prev: any) => {
          const newObj = structuredClone(prev);
          newObj[key]["balance"] = balance;
          return newObj;
        });
      });
    }, 2000);
  };

  const getDepositStatus = (id?: string) => {
    const balanceInterval = setInterval(() => {
      getDeposit(`/deposit/${id ?? depositDetail._id}`).then((resp) => {
        const detail = resp?.data;
        setDepositDetail(detail)
        if (detail.status !== 'pending') {
          toast.success("Deposit success");
          setDepositLoading(false);
          setDepositLoaded(true);
          clearInterval(balanceInterval);
        }
      });
    }, 2000);
  };

  const handleSenderSessionSubmit = useCallback(() => {
    setSenderLoader(true);
    getSessionID(`/session-nodes/${senderDetails?.sessionId}?stepId=kyc`).then(
      (resp: any) => {
        if (resp?.data) {
          setSenderKyc(resp?.data?.data);
        }
        setSenderLoader(false);
      }
    );
    getWallet(`/wallet/${senderDetails?.sessionId}`).then((resp: any) => {
      if (resp?.data?._id) {
        const { metadata } = resp?.data ?? {};

        getBalance("senderDetails", metadata?.EthAddr);
        setSenderRecipientDetails((prev: any) => {
          const newObj = structuredClone(prev);
          const obj = {
            polygonId: metadata?.PolygonId,
            walletAddress: metadata?.EthAddr,
            isApiResp: true,
          };
          newObj["senderDetails"] = { ...senderDetails, ...obj };
          return newObj;
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderDetails?.sessionId]);

  const handleRecipientSessionSubmit = useCallback(() => {
    setRecipientLoader(true);
    getSessionID(
      `/session-nodes/${recipientDetails?.sessionId}?stepId=kyc`
    ).then((resp: any) => {
      if (resp?.data) {
        setRecipientKyc(resp?.data?.data);
      }
      setRecipientLoader(false);
    });
    getWallet(`/wallet/${recipientDetails?.sessionId}`).then((resp: any) => {
      if (resp?.data?._id) {
        const { metadata } = resp?.data ?? {};

        getBalance("recipientDetails", metadata?.EthAddr);
        setSenderRecipientDetails((prev: any) => {
          const newObj = structuredClone(prev);
          const obj = {
            polygonId: metadata?.PolygonId,
            walletAddress: metadata?.EthAddr,
            isApiResp: true,
          };
          newObj["recipientDetails"] = { ...recipientDetails, ...obj };
          return newObj;
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientDetails?.sessionId]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key?: string) => {
    const { name, value } = e.target;

    setSenderRecipientDetails((prev: any) => {
      const newObj = structuredClone(prev);
      if (key) {
        newObj[key][name] = value;
      } else {
        newObj[name] = value;
      }

      return newObj;
    });
  };

  const { get: getSessionInfo } = useNetwork();
  const handleOnReleaseFund = () => {
    setIsReleaseEscrowLoading(true);
    //@avinash:call api for release fund
    notified.current.sender = false;
    notified.current.recipient = false
    releaseFund("/release-funds", {
      type: "complex",
      sender: {
        sessionId: senderRecipientDetails.senderDetails.sessionId,
      },
      recipient: {
        sessionId: senderRecipientDetails.recipientDetails.sessionId,
      },
      amount: senderRecipientDetails.senderAmount,
    })
      .then((resp) => {
        let status: string | number | NodeJS.Timer | undefined;
        status = setInterval(() => {
          getSessionInfo(`/release-funds/${resp._id}`).then((data) => {
            const { sender, recipient } = data.data ?? {};

            if (
              sender.status === "completed"
              && !notified.current.sender
            ) {
              notified.current.sender = true
              toast.success("Sender approved");
              toast.success("Fund moved escrow");
            }
            if (
              recipient.status === "completed"
              && !notified.current.recipient
            ) {
              notified.current.recipient = true
              toast.success("Recipient approved");
            }

            if (
              sender.status === "completed" &&
              recipient.status === "completed"
            ) {
              clearInterval(status);
              setIsReleaseEscrowLoading(false);
            }
            setSenderRecipientDetails((prev: any) => ({
              ...prev,
              sessionInfo: data.data,
            }));
          });
        }, 2000);

        getUserDetails(
          `/complex-session-details/${senderRecipientDetails.senderDetails.sessionId}`
        ).then((userDetails) => {
          const { metadata } = userDetails?.data ?? {};
          const { name, email } = metadata?.deviceInfo ?? {};
          const { country_code, region, country, city } =
            metadata?.sessionMetadata?.userAddress ?? {};
          const payload = {
            userName: name ?? "N/A",
            email: email ?? "N/A",
            city: city ?? "N/A",
            country: country ?? "N/A",
            countryCode: country_code ?? "N/A",
            region: region ?? "N/A",
          };

          setSenderRecipientDetails((prev: any) => {
            const newObj = structuredClone(prev);
            newObj["senderDetails"]["userDetails"] = payload;
            return newObj;
          });
          // setSenderRecipientDetails((prev: any) => ({
          //   ...prev,
          //   userDetails: payload,
          // }));
        });

        getUserDetails(
          `/complex-session-details/${senderRecipientDetails.recipientDetails.sessionId}`
        ).then((userDetails) => {
          const { metadata } = userDetails?.data ?? {};
          const { name, email } = metadata?.deviceInfo ?? {};
          const { country_code, region, country, city } =
            metadata?.sessionMetadata?.userAddress ?? {};
          const payload = {
            userName: name ?? "N/A",
            email: email ?? "N/A",
            city: city ?? "N/A",
            country: country ?? "N/A",
            countryCode: country_code ?? "N/A",
            region: region ?? "N/A",
          };

          setSenderRecipientDetails((prev: any) => {
            const newObj = structuredClone(prev);
            newObj["recipientDetails"]["userDetails"] = payload;
            return newObj;
          });
        });
      })
      .catch((error) => console.log({ error }))
      .finally(() => {});
  };

  const userDetails = {
    senderDetails: senderDetails?.userDetails,
    recipientDetails: recipientDetails?.userDetails,
  };

  const isDepositWaiting = useMemo(() => {
    if (!depositDetail.status) {
      return false
    }
    return !waitingStatus.includes(depositDetail.status);
  }, [depositDetail.status]) || depositLoading;

  const handleDeposit = () => {
    setDepositLoading(true);
    setDepositDetail({});
    setDepositLoaded(false)
    deposit("/deposit", {
      // selectedServerIndex: 0,
      address: senderDetails.walletAddress,
      sessionId: senderDetails?.sessionId,
      // label: "Master",
      // assetId: 2,
      // toAddress: recipientDetails.walletAddress,
      amount,
    }).then((resp) => {
      console.log('-0', resp);
      setDepositDetail(resp);
      getDepositStatus(resp._id)
      setSenderRecipientDetails((prev: any) => {
        const newObj = structuredClone(prev);
        newObj["senderAmount"] = amount;
        return newObj;
      });
    })
  };

  const handleMoveToEscrow = () => {};
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 16,
          width: "100%",
        }}
      >
        <div className="tc_wrapper" style={{ position: "relative" }}>
          <div
            style={{
              display: "flex",
              gap: 16,
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Input
              label={"Session ID"}
              inputType={"text"}
              inputName="sessionId"
              placeholder={"Session ID"}
              handleChange={(e) => handleChange(e, "senderDetails")}
              isShowButton={true}
              buttonLabel={senderLoader ? "" : "Submit"}
              handleClickBtn={handleSenderSessionSubmit}
              buttonLoader={senderLoader}
              buttonClassName="send_button"
            />

            {senderPoligonId && (
              <div>
                <div style={{ color: "#6C7489", fontWeight: 500 }}>
                  Polygon ID
                </div>
                <div style={{ wordWrap: "break-word" }}>{senderPoligonId}</div>
              </div>
            )}

            {senderWalletAddress && (
              <div>
                <div style={{ color: "#6C7489", fontWeight: 500 }}>
                  Wallet address
                </div>
                <div>
                  <a target="__blank" href={`https://mumbai.polygonscan.com/address/${senderWalletAddress}`}>
                    {senderWalletAddress}
                  </a>
                  </div>
              </div>
            )}

            {(senderBalance || senderBalance === 0) && (
              <div>
                <div style={{ color: "#6C7489", fontWeight: 500 }}>Balance</div>
                <div>{`$${senderBalance ?? 0.0}`}</div>
              </div>
            )}

            {isSenderApiResp && (
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Input
                  label={"Amount"}
                  inputType={"number"}
                  placeholder={"Amount"}
                  inputName="senderAmount"
                  handleChange={(e) => setAmount(Number(e.target.value))}
                  handleClickBtn={handleDeposit}
                  isShowButton={true}
                  buttonLabel="Deposit"
                  buttonLoader={isDepositWaiting}
                  isButtonDisabled={amount <= 0}
                />

                {/* <Button
                  label="Move to Escrow"
                  handleClick={handleMoveToEscrow}
                  type="button__filled--primary button__large"
                  disabled={false}
                /> */}
              </div>
            )}
          </div>
          <div className="connector">
            <i className="ri-arrow-right-line"></i>
          </div>
        </div>

        <div className="tc_wrapper" style={{ position: "relative" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              alignItems: "center",
              gap: 8,
            }}
          >
            <div style={{ color: "#000", fontSize: "32px", fontWeight: 600 }}>
              {escrowBalance ? `$${Number(escrowBalance).toFixed(2) ?? 0.0}` : '--'}
              <span style={{ fontSize: "14px", fontWeight: 400 }}> {escrowBalance ? "USD" : ""}</span>
            </div>
            <div>Escrow Balance</div>

            <div>
              <div>
              Escrow: <a target="__blank" href="https://mumbai.polygonscan.com/address/0x070DfaEB0D1A00556f4542ee495FD0233d36c675">
                0x070DfaEB0D1A00556f4542ee495FD0233d36c675
              </a>
              </div>
              <div>
                Asset: <a target="__blank" href="https://mumbai.polygonscan.com/address/0x070DfaEB0D1A00556f4542ee495FD0233d36c675">
                0xBCD6A85e594f0E415670894735CCeE79c563c634
              </a>
              </div>
            </div>

            {isSenderApiResp &&
              (!isReleaseEscrowLoading ? (
                <Button
                  label="Initiate escrow"
                  handleClick={handleOnReleaseFund}
                  type="button__filled--primary button__large"
                  // disabled={!depositLoaded}
                />
              ) : (
                <div>
                  <Loader type="loader" dimension={20} />
                  <div
                    style={{
                      color: "#6C7489",
                      fontSize: "14px",
                      fontWeight: 500,
                      paddingTop: "8px",
                    }}
                  >
                    Waiting for sender & recipient facial authentication
                  </div>
                </div>
              ))}
          </div>
          <div className="connector">
            <i className="ri-arrow-right-line"></i>
          </div>
        </div>

        <div className="tc_wrapper">
          <div
            style={{
              display: "flex",
              gap: 16,
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Input
              label={"Session ID"}
              inputType={"text"}
              placeholder={"Session ID"}
              inputName="sessionId"
              handleChange={(e) => handleChange(e, "recipientDetails")}
              isShowButton={true}
              buttonLabel={recipientLoader ? "" : "Submit"}
              handleClickBtn={handleRecipientSessionSubmit}
              buttonLoader={recipientLoader}
              buttonClassName="send_button"
            />
            {recipientPoligonId && (
              <div>
                <div style={{ color: "#6C7489", fontWeight: 500 }}>
                  Polygon ID
                </div>
                <div style={{ wordWrap: "break-word" }}>
                  {recipientPoligonId}
                </div>
              </div>
            )}

            {recipientWalletAddress && (
              <div>
                <div style={{ color: "#6C7489", fontWeight: 500 }}>
                  Wallet address
                </div>
                <div>
                  <a target="__blank" href={`https://mumbai.polygonscan.com/address/${recipientWalletAddress}`}>
                    {recipientWalletAddress}
                  </a>
                </div>
              </div>
            )}

            {(recipientBalance || recipientBalance === 0) && (
              <div>
                <div>Balance</div>
                <div>{`$${recipientBalance ?? 0.0}`}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <KYC
        SenderKyc={senderKyc}
        sessionDetailsInfo={senderRecipientDetails?.sessionInfo ?? {}}
        userDetails={{ ...userDetails }}
        RecipientKyc={recipientKyc}
        isSender={isSenderApiResp}
        isRecipient={isRecipientApiResp}
      />
    </>
  );
};
