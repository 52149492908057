import { FC } from 'react';
// import { Rejection } from 'views/sessions/store';

interface IRejectionDetails {
	data: any[];
}

export const RejectionDetails: FC<IRejectionDetails> = ({ data }) => {
	return (
		<>
			{data?.length > 0
				? data.map((el: any) => <li key={el.message}>{el.message}</li>)
				: 'NA'}
		</>
	);
};
