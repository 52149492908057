import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";

import "./input.scss";
import { Button } from "storybook/button";
import { JsxElement } from "typescript";

interface IInput {
  label: string;
  inputType: "text" | "number" | "password" | "date";
  placeholder: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  isCountryCodeVisible?: boolean;
  handleChangeCountry?: (e: any) => void;
  isShowButton?: boolean;
  handleClickBtn?: () => void;
  errorMessage?: string;
  isError?: boolean;
  handleClearField?: () => void;
  handleKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  allowClear?: boolean;
  id?: string;
  handleBlur?: () => void;
  prefixIcon?: string;
  disabled?: boolean;
  suffixIcon?: string;
  handleSuffixIcon?: () => void;
  autoComplete?: string;
  countryCode?: string;
  inputName?: string;
  maxLength?: number;
  autoFocus?: boolean;
  description?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  buttonLabel?: string;
  buttonLoader?: boolean;
  buttonClassName?: string;
  isButtonDisabled?: boolean;
}

export const Input: FC<IInput> = ({
  label,
  inputType,
  id,
  placeholder,
  handleChange,
  value,
  handleKeyPress,
  allowClear = false,
  handleClearField,
  isError = false,
  errorMessage,
  isShowButton,
  handleClickBtn,
  isCountryCodeVisible,
  handleChangeCountry,
  handleBlur = () => ({}),
  prefixIcon,
  disabled = false,
  suffixIcon,
  handleSuffixIcon,
  countryCode,
  autoComplete,
  inputName,
  maxLength,
  autoFocus = false,
  description,
  onKeyDown,
  isRequired = false,
  buttonLabel = "Add",
  buttonLoader = false,
  buttonClassName = "",
  isButtonDisabled = false,
}) => {
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();
    // Format the next day as 'YYYY-MM-DD'
    const formattedNextDay: any = currentDate.toISOString().split("T")[0];
    // Set the maxDate state to the formatted next day
    setMaxDate(formattedNextDay);
  }, [inputType]);

  return (
    <div className="input">
      {label && (
        <label htmlFor={id} className="input__label">
          {label}
          {isRequired && <span className="input__isRequired"> *</span>}
        </label>
      )}
      {description && <div className="input__description">{description}</div>}
      <div className="input__value-container">
        <div className="input__group">
          <div
            className={`input__text-field input__text-field${
              isError ? "--error" : ""
            }`}
          >
            {prefixIcon && <i className={prefixIcon} />}
            <input
              placeholder={placeholder}
              type={inputType}
              name={inputName}
              onBlur={handleBlur}
              id={id}
              className="input__field"
              onChange={handleChange}
              value={value}
              onKeyPress={handleKeyPress}
              disabled={disabled}
              autoComplete={autoComplete}
              maxLength={maxLength}
              autoFocus={autoFocus}
              onKeyDown={onKeyDown}
              max={maxDate}
            />
            {allowClear && (
              <i
                className="ri-close-line input__delete"
                onClick={handleClearField}
              />
            )}
            {suffixIcon && (
              <i
                className={`${suffixIcon} input__view`}
                onClick={handleSuffixIcon}
              />
            )}
          </div>
          {isShowButton && (
            <Button
              label={buttonLabel}
              handleClick={handleClickBtn}
              type={`button__outline button__large ${buttonClassName}`}
              loader={buttonLoader}
              disabled={isButtonDisabled}
            />
          )}
        </div>

        {isError && errorMessage && (
          <span className="input__error">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
